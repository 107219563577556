import React, { useState } from 'react';
import {
  HomePageVideo,
  VideoCarousel,
  Layout,
  Separator,
  ImageGallery,
  SEO,
  RenderWhenReady,
  WomenPolicy,
} from 'components';

export default () => {
  const [playingVimeoVideoCode, setPlayingVimeoVideoCode] = useState();

  const handleVideoSelection = (vimeoCode) => {
    setPlayingVimeoVideoCode(vimeoCode);
  };
  return (
    <Layout>
      <RenderWhenReady>
        <SEO
          pageSpecificTitle="Welcome"
          thumbnailImageAlt="Several successful female Irish athletes posing with young children"
          keywords={[
            'sport ireland',
            'love sport',
            'women in sport',
            'Roisin Upton',
            'Róisín Upton',
            'Fiona Coghlan',
            'Emma Johnstone',
            'olympics',
            'special olympics',
          ]}
        />
        <Separator includesWomenPolicyTag>
          <h4>Click To Watch Video</h4>
          <WomenPolicy />
        </Separator>
        <HomePageVideo
          handleVideoSelection={handleVideoSelection}
          playingVimeoVideoCode={playingVimeoVideoCode}
        />
        <VideoCarousel handleVideoSelection={handleVideoSelection} />
        <Separator>
          <h4>Click To Enlarge Images</h4>
        </Separator>
        <ImageGallery />
        <Separator>
          <h4>Contact Us</h4>
        </Separator>
      </RenderWhenReady>
    </Layout>
  );
};
